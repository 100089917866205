import { log } from './modules/log.js';
import { collectData, collectIdentity } from './modules/fetch.js';

import '../css/main.less';
import { changeTheme } from './modules/themeChange';

//collectData();
//collectIdentity();

themeButton.addEventListener('click', () => {
    changeTheme();
})

console.log("HELLO THARRRR")