export function changeTheme() {
    if (document.getElementById("themeContainer").classList.contains("lightTheme")) {
        localStorage.setItem('themeToggle', 'dark');
        document.getElementById("themeContainer").classList.replace("lightTheme", "darkTheme");
        document.getElementById("themeContainer").animate([
            { opacity: 0.3, easing: 'ease-out' },
            { opacity: 0.7, easing: 'ease-in' },
            { opacity: 1 }],
            200);
    } else {
        localStorage.setItem('themeToggle', 'light');
        document.getElementById("themeContainer").classList.replace("darkTheme", "lightTheme");
        document.getElementById("themeContainer").animate([
            { opacity: 0.5, easing: 'ease-out' },
            { opacity: 0.7, easing: 'ease-in' },
            { opacity: 1 }],
            200);
    }
}

function randomListColors() {
    let elements = document.getElementsByClassName('source')
    elements.style.color = calculateTheColor();
}

// this function changes the color of the above CSS IDs associated with the HTML elements by calculating an HSL color value 
function calculateTheColor() {
    return 'hsl(' + (360 * Math.random()) + ',50%,50%)'; // H,S,L
}